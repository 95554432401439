<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'door-create create-form'"
    v-if="getPermission('door:create') || getPermission('door:update')"
  >
    <template v-slot:header-title>
      <!--  <h1 class="form-title d-flex">
        <template v-if="doorId">Update</template>
        <template v-else>Create</template> Door
      </h1> -->
      <div class="form-action">
        <div class="form-title">
          Door for
          <span
            class="form-title-link cursor-pointer"
            v-on:click="selectCustomer()"
          >
            <template v-if="vCustomerId">{{ vCustomer.display_name }}</template>
            <template v-else>
              Select a Customer
              <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
            </template>
          </span>
        </div>
      </div>
      <!-- <div class="form-title">
        Door for
        <span
          class="form-title-link cursor-pointer"
          v-on:click="selectCustomer()"
        >
          <template v-if="vCustomerId">{{ vCustomer.display_name }}</template>
          <template v-else>
            Select a Customer
            <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
          </template>
        </span>
      </div> -->
    </template>

    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button text-white"
          color="cyan"
          depressed
          v-on:click="onSubmit"
        >
          <template v-if="doorId">Update</template>
          <template v-else>Save</template> Door
        </v-btn>
        <v-btn
          v-on:click="goBack"
          :disabled="formLoading || pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          outlined
          depressed
          >Cancel
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <div class="door-create create-form" v-if="getPermission('door:create')">
        <v-form
          ref="doorForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-container fluid class="white-background">
            <DoorCreate
              :duplicateId="duplicateId"
              :doorId="doorId"
              :barcodeData="barcode"
              :doorCreateData="doorCreate"
              v-on:saveDoor="updateDoor"
            ></DoorCreate>
          </v-container>
        </v-form>
      </div>
      <customer-select :key="`door-customer-select-${dialog_key}`" />
      <customer-create :key="`door-customer-create-${dialog_key}`" />
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ServiceMixin from "@/core/plugins/product-mixin";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import DoorCreate from "@/view/pages/door/partials/Create.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import {
  SET_DIALOG_STATUS,
  CLEAR_CUSTOMER,
  SET_CUSTOMER,
} from "@/core/services/store/customer.module";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin, ServiceMixin],
  name: "service-create",
  title: "Create Service",
  data() {
    return {
      formValid: true,
      doorCreated: false,
      barcode: null,
      dialog_key: Number(new Date()),
      barcodeDialog: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      doorCreate: null,
      doorId: null,
      duplicateId: null,
      pageLoading: false,
    };
  },
  components: {
    DoorCreate,
    CustomerSelect,
    CustomerCreate,
    CreateUpdateTemplate,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    selectCustomer() {
      this.$store.commit(SET_DIALOG_STATUS, true);
    },
    updateDoor(param) {
      this.doorCreate = param;
    },
    getDoor(doorId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (doorId && doorId > 0) {
          _this.$store
            .dispatch(GET, { url: "door/" + doorId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },
    onSubmit() {
      const _this = this;

      if (!_this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Please Select Customer.");
        return false;
      }

      if (!_this.$refs.doorForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "door";

      if (_this.doorId && _this.doorId > 0) {
        requestType = PUT;
        requestURL = "door/" + _this.doorId;
      }

      _this.$store
        .dispatch(requestType, {
          url: requestURL,
          data: { ..._this.doorCreate, customer: _this.vCustomerId },
        })
        .then(async (response) => {
          _this.doorId = response.data ? response.data.id : null;
          _this.doorCreated = true;
          _this.$router.push(
            _this.getDefaultRoute("door", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getDoor(_this.doorId)
        .then((response) => {
          if (response?.customer_relation?.default_person) {
            delete response?.customer_relation?.default_person;
          }

          _this.$store.commit(SET_CUSTOMER, response?.customer_relation);

          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Door", route: "door" },
              { title: "Update" },
            ]);
            _this.barcode = response.barcode;
            _this.doorCreate = {
              barcode: response.barcode,
              name: response.name,
              description: response.description,
              product_type: response.product_type,
              door_type: response.door_type,
              images: response.images,
              length: response.length,
              width: response.width,
              height: response.height,

              length_unit: response.length_unit,
              width_unit: response.width_unit,
              height_unit: response.height_unit,

              services: response.services,
              product_images: [], // response.product_images,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getDoor(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            /*  _this.barcode = response.barcode; */

            _this.doorCreate = {
              //barcode: this.barcode,
              name: response.name,

              description: response.description,
              product_type: response.product_type,
              images: response.images,
              length: response.length,
              width: response.width,
              height: response.height,

              length_unit: response.length_unit,
              width_unit: response.width_unit,
              height_unit: response.height_unit,

              services: response.services,
              /*    product_images: response.product_images, */
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.doorId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  destroyed() {
    this.$store.commit(CLEAR_CUSTOMER);
  },
  computed: {
    ...mapGetters(["vCustomer", "vCustomerId"]),
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Door", route: "door" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.door.update" ||
      _this.$route.name == "engineer.door.update"
    ) {
      _this.updateMode = true;

      if (_this.doorId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.doorCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
